import axios from "axios";

async function ExpireSession_get(accountId) {

    axios.get(`https://admin.clairaudience.co.kr/api/admin/v1/manager/logout/${accountId}`)
        .then(response => {
            console.log("성공");

        })
        .catch(error => {
            console.log("실패");
        });


}

export default ExpireSession_get;