import axios from "axios";

async function C_Enable_post(accountId,setCount,count) {

    axios.post(`https://admin.clairaudience.co.kr/api/manager/v1/synesper-lite-cardio/user/enable/${accountId}`)
        .then(response => {
            console.log("성공");
            const num = count+1;
            setCount(num);
        })
        .catch(error => {
            console.log("실패");
        });


}

export default C_Enable_post;