"use strict";
function extentionArray(PCGFloatArray,PPGOriginalArray,measureId) {


    //PCG의 데이터수/PPG의 데이터 수
    const repeatCount = Math.round(Object.keys(PCGFloatArray).length/Object.keys(PPGOriginalArray).length);

    const PPGRepeatArray =  repeatElements(PPGOriginalArray,repeatCount);


    /*


    //음성의 샘플링 계수
    const sr =  16000;
    const lowcut = 30;
    const highcut = 500;
    const filterOrder = 101; // 필터의 길이(탭 수)
    //const PCGPassedArray = BandPassFilter(PCGFloatArray,sr,lowcut,highcut);

    //console.log(PCGPassedArray)

    const bandpassCoeffs = generateBandpassCoefficients(sr, lowcut, highcut, filterOrder);

    */




// 필터 적용
    //const PCGfilteredArray = applyFIRFilter(PCGFloatArray, bandpassCoeffs);

    const sr =  16000;
    const cutoff = 50;
    const PCGfilteredArray = Butterworth(PCGFloatArray, sr, cutoff);


    //
    const per_data = DWavelet(PCGfilteredArray);
    console.log("?????????????????????");
    console.log(per_data);


//시작지점 = 데이터의 10초 뒤 지점(10*16000)

    const startPoint = 80000;
    const showPeriod = 240000;




    const CutPPG = PPGRepeatArray.slice(startPoint,startPoint + showPeriod);
    const CutPCG = per_data.slice(startPoint,startPoint + showPeriod);





    const nomalP = MinMaxNomalization(CutPPG);
    const nomalC = MinMaxNomalization(CutPCG);


//데이터 반복 함수
    function repeatElements(array, repeatCount){
        const result = [];
        for (const item of array) {
            result.push(...Array(repeatCount).fill(item));
        }

        return result;
    }

    function MinMaxNomalization(array){
        const min = array.reduce((a, b) => Math.min(a, b));
        const max = array.reduce((a, b) => Math.max(a, b));

        if(min===0&&max===0)
        {
            return array.map(x => 0);
        }

        return array.map(x => 2 * ((x - min) / (max - min)) - 1)
    }


    const testP = extractEveryTenthNumber(nomalP)
    const testC = extractEveryTenthNumber(nomalC)

    /*
        console.log("test P  C");
    console.log(testP);
    console.log(testC);

     */

    return {
        "PPG" : testP,
        "PCG" : testC,
    };
}




/*  ------------------------------------------   */

const Fili = require('fili');
//버터워스 필터
function Butterworth(data, sr, cutoff){
    const low = ButterworthLowPassFilter(data, sr, cutoff)
    const high = ButterworthHighPassFilter(low, sr, cutoff)
    return high
}

function ButterworthLowPassFilter(data, sr, cutoff){
    console.log('BL')
// 필터 설정
    var iirCalculator = new Fili.CalcCascades();

    var iirFilterCoeffs = iirCalculator.lowpass({
        order: 4, // 4번째 종속 바이쿼드 필터 (max: 12)
        characteristic: 'butterworth',
        Fs: sr, // 샘플링 속도
        Fc: cutoff, // 컷오프
        BW: 1, // bandwidth only for bandstop and bandpass filters - optional
        gain: 0, // gain for peak, lowshelf and highshelf
        preGain: false // adds one constant multiplication for highpass and lowpass
        // k = (1 + cos(omega)) * 0.5 / k = 1 with preGain == false
    });

    var filter = new Fili.IirFilter(iirFilterCoeffs);
    const filteredData = filter.multiStep(data);
    return filteredData

}

function ButterworthHighPassFilter(data, sr, cutoff){
    console.log('BH')

    const iirCalculator = new Fili.CalcCascades();

    var iirFilterCoeffs = iirCalculator.highpass({
        order: 4, // cascade 3 biquad filters (max: 12)
        characteristic: 'butterworth',
        Fs: sr, // sampling frequency
        Fc: cutoff, // cutoff frequency / center frequency for bandpass, bandstop, peak
        BW: 1, // bandwidth only for bandstop and bandpass filters - optional
        gain: 0, // gain for peak, lowshelf and highshelf
        preGain: false // adds one constant multiplication for highpass and lowpass
        // k = (1 + cos(omega)) * 0.5 / k = 1 with preGain == false
    })

    var filter = new Fili.IirFilter(iirFilterCoeffs);
    const filteredData =  filter.multiStep(data)
    return filteredData
}






///////////////////////////////////////////////////////////////////////////////////////////

Object.defineProperty(exports, "__esModule", { value: true });
var wt = require("discrete-wavelets");


function splitSignal(signal, chunkSize) {
    const chunks = [];
    for (let i = 0; i < signal.length; i += chunkSize) {
        chunks.push(signal.slice(i, i + chunkSize));
    }
    return chunks;
}

// 청크를 병합하는 함수
function mergeChunks(chunks) {
    return chunks.flat();
}

// DWT 및 IDWT 처리 함수
function DWavelet(signal, chunkSize = 256, wavelet = 'haar', level = 4) {
    try {
        console.log('Original Signal Length:', signal.length);

        // 신호를 청크로 분할
        const signalChunks = splitSignal(signal, chunkSize);
        console.log('Number of Chunks:', signalChunks.length);

        const transformedChunks = [];
        const reconstructedChunks = [];

        // 각 청크에 대해 DWT 및 IDWT 수행
        for (const chunk of signalChunks) {
            console.log('Processing Chunk:', chunk);
            console.log('wt:', wt);
            console.log('wt.wavedec:', wt.default.wavedec);
            // DWT 수행
            const transformed = wt.default.wavedec(chunk, wavelet, 'symmetric', level);
            transformedChunks.push(transformed);
            console.log("transformed: ",transformed);

            // IDWT 수행
            const reconstructed = wt.default.waverec(transformed, wavelet);
            reconstructedChunks.push(reconstructed);
            console.log("reconstructed: ",reconstructed);
        }

        // 변환 결과 병합
        const finalReconstructedSignal = mergeChunks(reconstructedChunks);
        console.log("finalReconstructedSignal: ",finalReconstructedSignal);

        //console.log('Final Reconstructed Signal:', finalReconstructedSignal);
        return finalReconstructedSignal;
    } catch (error) {
        console.error('Error during signal processing:', error.message);
    }
}

///////////////////////////////////////////////////////////////////////////////////////////


/*  데이터 갯수 1/10로 줄이기  */
function extractEveryTenthNumber (numbers) {
    return numbers.filter((_, index) => (index + 1) % 10 === 0);
}


function Chart_Data(PCGFloatArray,PPGOriginalArray,measureId) {


    let size = 50;




    let data_box = {
        "PPG" : PCGFloatArray,
        "PCG" : PPGOriginalArray,
    };

    if(PCGFloatArray.length > 10 )
    {
        data_box = extentionArray(PCGFloatArray,PPGOriginalArray,measureId);
        size = 24000;
    }



    const labels = Array.from({ length: size }, (_, i) => (i + 1)/1600);
    const formattedNumbers = labels.map(number => number.toFixed(1));



    const data = {
        labels: formattedNumbers,
        datasets: [
            {
                label: 'PCG',
                data: data_box.PCG,
                borderColor: 'blue',
                backgroundColor: 'rgba(153, 102, 255, 0.2)',
                borderWidth: 0.5,
                yAxisID: 'y1',
                pointRadius: 0,
            },
            {
                label: 'PPG',
                data: data_box.PPG,
                borderColor: 'red',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderWidth: 1.0,
                yAxisID: 'y1',
                pointRadius: 0,
            }

        ]
    };


    const options = {
        maintainAspectRatio: false,
        animation: false,
        plugins: {
            legend: {
                display: false, // 범례 숨기기
            },
            title: {
                display: false, // 제목 숨기기
            },
            tooltip: {
                enabled: false, // 툴팁은 유지
            },
        },
        scales: {
            y1 : {
                display: false, // y축 숨기기
            },
        },
        hover: {
            mode: null,
        }
    };

    const chart_data = {
        "data" : data,
        "options" : options,
    }


    return chart_data;
}

export default Chart_Data;

