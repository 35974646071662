import axios from "axios";

async function F_ExpireSession_get(accountId) {

    axios.get(`https://admin.clairaudience.co.kr/api/manager/v1/synesper-lite-family/user/expire-session/${accountId}`)
        .then(response => {
            console.log("성공");

        })
        .catch(error => {
            console.log("실패");
        });


}

export default F_ExpireSession_get;