import axios from "axios";

async function UserList_get(now_page,search_name) {

    //url: `https://admin.clairaudience.co.kr/api/manager/v1/synesper-lite-cardio/user?page=${now_page}&size=20`,

    let Url;
    if(search_name === null)
    {
        Url = `https://admin.clairaudience.co.kr/api/manager/v1/synesper-lite-cardio/user?page=${now_page}&size=20`;
    }
    else{
        Url = `https://admin.clairaudience.co.kr/api/manager/v1/synesper-lite-cardio/user?page=${now_page}&size=20&userName=${search_name}`;
    }


    try {

        const response = await axios({
            url: Url,
            method: 'GET',
        });
        if (response.status === 200) {
            console.log("성공");
            console.log("STATUS : ", response.data);
            return response.data;
        } else {
            console.log("STATUS : ", response.status);
        }
    } catch (error) {
        console.log('로그인 에러: ', error);
    }


}

export default UserList_get;