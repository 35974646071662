import axios from "axios";

function F_Cumulative_number_users_get(setCumulative_users_data) {

    axios.get(`https://admin.clairaudience.co.kr/api/manager/v1/synesper-lite-family/statistics/cumulative-number-of-users`)
        .then(response => {

            console.log("users");
            console.log(response.data);


            response.data.labels = response.data.labels.map(label=>label.split('T')[0]);
            console.log(response.data);
            setCumulative_users_data(response.data);
        })
        .catch(error => {
            console.log("실패");
        });


}

export default F_Cumulative_number_users_get;